import { Collapse } from "bootstrap";

export const sidebarExpand = () => {
	const collapseTogglers = document.querySelectorAll(".sidebar [data-collapse-target]");
	collapseTogglers.forEach(togglerElm => {

		togglerElm.addEventListener("click", function(){
			let isExpanded = this.getAttribute("data-expanded");
			if(isExpanded) {
				isExpanded = isExpanded == "true" ? false : true;
				this.setAttribute("data-expanded", isExpanded);
			}

			const targetElm = document.querySelectorAll(this.getAttribute("data-collapse-target"));
			if(!targetElm)
				return;
			targetElm.forEach(item => new Collapse(item, { parent: "ul.sidebar-nav" }));
		});

	});
};