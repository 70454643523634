import "../scss/app.scss";

// AdminKit (required)
import "./modules/bootstrap";
import "./modules/sidebar";
import "./modules/theme";
import "./modules/feather";

import { sidebarExpand } from "./custom-modules/sidebar-expand";
import { useBsTooltip } from "./custom-modules/useBsTooltip";

window.addEventListener("DOMContentLoaded", () => {
	sidebarExpand();
	useBsTooltip();
});

// Charts
// import "./modules/chartjs";

// Forms
// import "./modules/flatpickr";

// Maps
// import "./modules/vector-maps";